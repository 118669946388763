import { Link, graphql } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import "./case-study.css"

const BgImage = styled.div`
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease 250ms;
    background-image: url(${props => props.background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
  }
`

class CaseStudies extends React.Component {
  constructor() {
    super()
    this.state = {
      categories: "All",
      class: "",
    }
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick(event) {
    event.preventDefault()
    this.setState({ categories: event.target.value, class: "animated fadeIn" })
  }

  render() {
    const { data } = this.props
    const siteTitle = "sitetitle"
    const filterCStud = data.allSanityCaseStudy.group.filter(
      fltr => fltr.fieldValue === this.state.categories
    )

    console.log("dataD", data)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="Case Studies | Quandary Consulting Group"
          description="See how our customers broke through bottlenecks, eliminated manual tasks, and achieved significant ROIs with custom low-code solutions."
        />

        <div style={{ paddingTop: "5rem" }}>
          <div id="pagetitle" className="page-intro mb-5">
            <div className="container">
              <div className="page-title-inner text-center">
                <h1 className="page-title font-weight-medium">Case Studies</h1>
                <p>
                  We transform how businesses work using Quickbase, Workato, and
                  Pipefy and other elite low-code tools.
                </p>
              </div>
            </div>
          </div>

          <Container className="pb-5">
            <ul className="style-unstyled cat-nav p-0 text-center text-left-mobile categories-wrapper">
              <li className="d-inline">
                <button value="All" onClick={this.handleClick}>
                  All
                </button>
              </li>
              {data.allSanityCaseStudy.group.map(tag => (
                <li className="d-inline" key={tag.fieldValue}>
                  <button value={tag.fieldValue} onClick={this.handleClick}>
                    {tag.fieldValue}
                  </button>
                </li>
              ))}
            </ul>
            <div>
              <Row className="justify-content-start mt-5">
                {this.state.categories === "All"
                  ? data.allSanityCaseStudy.edges.map(stdy => {
                      const StdyImg = getImage(
                        stdy.node.mainImage !== null
                          ? stdy.node.mainImage.asset.gatsbyImageData
                          : "https://source.unsplash.com/collection/2203755"
                      )

                      const StdyBgImg = convertToBgImage(StdyImg)

                      return (
                        <Col
                          md={4}
                          key={stdy.node.id}
                          className="p-relative mb-4"
                        >
                          <BgImage
                            // Tag="div"
                            className={`position-relative case-studies-wrapper ${this.state.class}`}
                            // Spread bgImage into BgImage:
                            background={StdyBgImg?.fluid?.src}
                            // preserveStackingContext
                            style={{ borderRadius: "5px", height: "370px" }}
                          >
                            <div className="c-stud">
                              <Link
                                to={`/case-study/${stdy.node.slug.current}/`}
                              >
                                <ul
                                  className="list-unstyled"
                                  style={{ height: "7rem" }}
                                >
                                  {stdy.node.categories.length !== 0
                                    ? stdy.node.categories.map(cat => (
                                        <li
                                          className="text-white"
                                          key={cat.title}
                                        >
                                          {/* {console.log("Cat All", cat)} */}
                                          {cat.title}
                                          <span>, </span>
                                        </li>
                                      ))
                                    : null}
                                </ul>
                                <p className="h5 text-white font-weight-bold">
                                  {stdy.node.title}
                                </p>
                                <div className="std-cat">
                                  <ul className="list-unstyled">
                                    {stdy.node.tag.length !== 0
                                      ? stdy.node.tag.map(tg => (
                                          <li
                                            className="text-white"
                                            key={tg.title}
                                          >
                                            {tg.title} <span>, </span>
                                          </li>
                                        ))
                                      : null}
                                  </ul>
                                </div>
                              </Link>
                            </div>
                          </BgImage>
                        </Col>
                      )
                    })
                  : filterCStud[0].edges.map(stud => {
                      const StdImg = getImage(
                        stud.node.mainImage !== null
                          ? stud.node.mainImage.asset.gatsbyImageData
                          : "https://source.unsplash.com/collection/2203755"
                      )
                      const StdBgImg = convertToBgImage(StdImg)

                      return (
                        <Col
                          md={4}
                          key={stud.node.id}
                          className="p-relative mb-4"
                        >
                          {/* {console.log("stud", stud)} */}
                          <BgImage
                            // Tag="div"
                            className={`position-relative case-studies-wrapper ${this.state.class}`}
                            // Spread bgImage into BgImage:
                            background={StdBgImg?.fluid?.src}
                            // {...StdBgImg}
                            // preserveStackingContext
                            style={{ borderRadius: "5px", height: "370px" }}
                          >
                            <div className="c-stud">
                              <Link
                                to={`/case-study/${stud.node.slug.current}/`}
                              >
                                <ul
                                  className="list-unstyled"
                                  style={{ height: "7rem" }}
                                >
                                  {stud.node.categories.length !== 0
                                    ? stud.node.categories.map(cat => (
                                        <li
                                          className="text-white"
                                          key={cat.title}
                                        >
                                          {cat.title}
                                          <span>, </span>
                                        </li>
                                      ))
                                    : null}
                                </ul>
                                <h4 className="text-white font-weight-bold">
                                  {stud.node.title}
                                </h4>
                                <div className="std-cat">
                                  <ul
                                    className="list-unstyled"
                                    style={{ height: "7rem" }}
                                  >
                                    {stud.node.tag.length !== 0
                                      ? stud.node.tag.map(tg => (
                                          <li
                                            className="text-white"
                                            key={tg.title}
                                          >
                                            {tg.title}
                                            <span>, </span>
                                          </li>
                                        ))
                                      : null}
                                  </ul>
                                </div>
                              </Link>
                            </div>
                          </BgImage>
                        </Col>
                      )
                    })}
              </Row>
            </div>
          </Container>

          <section id="quotes" style={{ padding: "5rem 0" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <h2 className="font-weight-medium">
                      Get Results Like These for Your Business
                    </h2>
                    <p>
                      We exist to make your business more efficient, productive,
                      and powerful.
                    </p>
                    <div className="justify-content-start mt-4">
                      <Link
                        className="btn btn-primary btn-arrow text-center"
                        to="/contact/"
                      >
                        Let's Chat
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default CaseStudies

export const casestudy = graphql`
  query Studies {
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "L")
          categories {
            title
          }
          tag {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
                width: 900
              )
            }
          }
          discription
          slug {
            current
          }
        }
      }
      group(field: categories___title) {
        fieldValue
        edges {
          node {
            id
            title
            categories {
              title
            }
            tag {
              title
            }
            mainImage {
              asset {
                gatsbyImageData(
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                  width: 900
                )
              }
            }
            slug {
              current
            }
            discription
          }
        }
      }
    }
  }
`
